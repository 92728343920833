/* stylelint-disable */
.oicLaunchJanison {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  h4 {
    margin: 0 0 0.5rem 0;

    div {
      p {
        font-size: 35px;
        font-weight: 700;
      }
    }

    button {
      margin-left: 5px;
    }
  }

  .assessmentsHeading {
    margin-top: 2.4rem;
  }
}
